import { reactive } from 'vue'
import { useWebSocket } from '@/websocket'
import { Participant } from '@/config/participant'
import kurentoUtils from 'kurento-utils'
import { nextTick } from 'vue'
import { ElMessage } from 'element-plus'

/**iceservers**/
const iceservers={
	"iceServers":[
		{
			urls:"stun:1.14.19.123:3478"
		},
		{
			urls:["turn:1.14.19.123:3478"],
			username:"admin",
			credential: "admin"
		}
	]
}
/**定义constraints类型**/
interface constraintsType{
	audio: boolean,
	video: any,
}
/**定义optionsType类型**/
interface optionsType{
	configuration: any,
	localVideo?: any,
	mediaConstraints?: any,
	onicecandidate?: any,
	videoStream?: any,
}

export default {
    namespaced: true,
	state: () => ({
		/**websocket数据**/
		ws: null,
		/**当前房间号**/
		room: '',
		/**当前房间信息**/
		roomInfo: {},
		/**流信息保存**/
		participants: {},
		/**当前选中摄像头全局保存**/
		deviceFocus: 'desk',
		/**屏幕共享的peer**/
		deskData: {},
		/**当前设备**/
		devicesOn: {
			video: '',
			audio: '',
			audiooutput: '',
			gainNodeValue: 1,
			volume: 1,
		},
		/**获取的媒体流**/
		streams: {
			data: null,
			videoStatus: 1,
			muteStatus: 1,
		},
		options: {},
	}),
	mutations: {
		/**保存媒体流**/
		setOptions(state:any, value:any){
			state.options = value
		},
		/**保存媒体流**/
		setStreams(state:any, value:any){
			state.streams = value
		},
		/**保存devicesOn**/
		setDevicesOn(state:any, parmas:any){
			const {keyName, data} = parmas
			state.devicesOn[keyName] = data
		},
		/**屏幕共享的peer**/
		setdeskData(state:any, value:any){
			state.deskData = value
		},
		/**保存当前选中摄像头**/
		setDeviceFocus(state:any, value:string){
			state.deviceFocus = value
		},
		/**保存websocket数据**/
		setWs(state:any, value:any){
			state.ws = value
		},
		/**保存当前房间号**/
		setRoomId(state:any, value:string | number){
			state.room = value
		},
		/**保存当前房间信息**/
		setRoomInfo(state:any, value:any){
			state.roomInfo = value
		},
		/**保存流信息**/
		setParticipant(state:any, value:any){
			if(Object.keys(value).length == 0){
				state.participants = {}
			}else{
				state.participants[value.name] = value
			}
		},
		/**删除流**/
		deleteParticipant(state:any, name: string){
			delete state.participants[name]
		},
	},
	actions: {
		/**开始websocket连接**/
		async webSocketStart({ commit, dispatch, state }:any, {onSuccess, onMicrophoneStatus, updateMode, onChat, onNewUser, onParticipantLeft, onRoomUsers, onUserLeave, onLiving, onClosed}:any){
			const { hostname, port, protocol } = location
			const w = protocol == 'https:' ? 'wss' : 'ws'
			let wsUri = `${w}://${hostname}:${port}/netty/groupcall`
			if(process.env.NODE_ENV === 'development') wsUri = `${w}://1.14.19.123/netty/groupcall`
			/**websocket连接存储**/
			let ws = await useWebSocket({
				wsUri: wsUri,
				onMessage: ({ data }:any) => {
					const { method, params } = JSON.parse(data)
					// console.log(11,method)
					// console.log(22,params)
					switch(method){
						case 'existingParticipants' :
							const { roomInfo: { url } } = state
							if(url == 'webcam') dispatch('onExistingParticipants', params)
							const { dataObj } = params
							if(dataObj && dataObj.length && typeof onLiving == 'function') onLiving()
							dataObj?.forEach((d:any) => {
								dispatch('receiveAudio', d)
							})
							break;
						case 'receiveVideoAnswer':
							dispatch('receiveVideoResponse', params)
							break;
						case 'iceCandidate':
							dispatch('onIceCandidate', params)
							break;
						case 'participantLeft':
							dispatch('onParticipantLeft', params)
							if(typeof onParticipantLeft == 'function') onParticipantLeft(params)
							break;
						case 'newParticipantArrived':
							dispatch('receiveAudio', params)
							if(typeof onLiving == 'function') onLiving()
							break;
						case 'microphoneStatus':
							if(typeof onMicrophoneStatus == 'function') onMicrophoneStatus(params)
							break;
						case 'updateMode':
							if(typeof updateMode == 'function') updateMode(params)
							break;
						case 'chat':
							if(typeof onChat == 'function') onChat(params)
							break;
						case 'newUser':
							if(typeof onNewUser == 'function') onNewUser(params)
							break;
						case 'roomUsers':
							if(typeof onRoomUsers == 'function') onRoomUsers(params)
							break;
						case 'userLeave':
							if(typeof onUserLeave == 'function') onUserLeave(params)
							break;
					}
				},
				onClosed: () => {
					if(typeof onClosed == 'function') onClosed()
				},
				onSuccess: () => {
					if(typeof onSuccess == 'function') onSuccess()
				}
			})
			commit('setWs', ws)
		},
		/**离开房间**/
		async leaveRoom({ commit, dispatch, state }:any, params:any){
			const { participants } = state
			dispatch('sendMessage', {method: 'leaveRoom'})
			for( let key in participants){
				participants[key].dispose()
			}
			await nextTick()
			commit('setParticipant', {})
			commit('setRoomInfo', {})
		},
		/**有主讲离开房间**/
		onParticipantLeft({ commit, dispatch, state }:any, params:any){
			const { name } = params
			let participant = state.participants[name]
			if(participant){
				participant.dispose()
			}
			commit('deleteParticipant', name)
		},
		/**onIceCandidate**/
		onIceCandidate({ commit, dispatch, state }:any, params:any){
			const { participants } = state
			participants[params.name].rtcPeer.addIceCandidate(params.candidate, function (error: any) {
				if (error) {
					return console.log("Error adding candidate: ", error);
				}
			});
		},
		/**receiveVideoResponse**/
		receiveVideoResponse({ commit, dispatch, state }:any, params:any){
			const { participants } = state
			participants[params.name].rtcPeer.processAnswer (params.sdpAnswer, function (error: any) {
				if (error) return console.log(error);
			});
		},
		/**延迟几秒继续向下执行代码**/
		setTimeoutFun({}, time: number){
			return new Promise<void>((resolve, reject) => {
				setTimeout(() => {
					resolve()
				}, time)
			})
		},
		/**receiveAudio**/
		async receiveAudio({ commit, dispatch, state }:any, params:any){
			const { name: sender, mode, muteFlag, camera } = params
			let participant = reactive(new (Participant as any)(`${sender}`, camera, muteFlag))
			commit('setParticipant', participant)
			interface constraintst{
				audio: any | true,
				video: any | true,
			}
			let constraints : constraintst = {
				audio : true,
				video: {
					width:{
						max: document.documentElement.clientWidth
					},
					height: document.documentElement.clientHeight,
					frameRate: 30
				}
			}
			if (mode == 'video-only') {
				constraints.audio = false;
			} else if (mode == 'audio-only') {
				constraints.video = false;
			}
			await nextTick()
			let video = document.getElementById('video' + sender)
			let options = {
				configuration: iceservers,
				remoteVideo: video,
				mediaConstraints : constraints,
				onicecandidate: participant.onIceCandidate.bind(participant)
			}
			participant.rtcPeer = new (kurentoUtils as any).WebRtcPeer.WebRtcPeerRecvonly(options, function(error:any) {
				if (error) return console.log(error)
				participant.rtcPeer.generateOffer(participant.offerToReceiveVideo.bind(participant))
			})
		},
		/**onExistingParticipants**/
		async onExistingParticipants({ commit, dispatch, state }:any, params:any){
			let { roomInfo: { mode, url, name }, streams } = state
			let constraints: constraintsType = {
				audio : true,
				video: {
					width:{
						max: document.documentElement.clientWidth
					},
					height: document.documentElement.clientHeight,
					frameRate: 30
				}
			}
			if (mode == 'video-only') {
				constraints.audio = false;
			} else if (mode == 'audio-only') {
				constraints.video = false;
			}
			let options: optionsType = {
				configuration: iceservers,
				// localVideo: video,
				mediaConstraints: constraints,
				// onicecandidate: participant.onIceCandidate.bind(participant)
			}
			// switch(state.deviceFocus){
			// 	case 'desk':
			// 		try{
			// 			let stream = await dispatch('getStream')
			// 			options.videoStream = stream
			// 		}catch (error) {
			// 			console.log(error)
			// 		}
			// 		break;
			// 	default:
			// 		if(options.mediaConstraints.video && state.deviceFocus){
			// 			options.mediaConstraints.video = { deviceId: { exact: state.deviceFocus }}
			// 		}
			// 		break;
			// }
			let camera = true
			let muteFlag = false
			let readyStatus = 0
			let message = {
				method: 'mute',
				params: {
					sender: name
				}
			}
			let message2 = {
				method: 'groupForward',
				params: {
					key: "camera",
					method: "updateMode",
					value: false,
					params: {
						name,
						camera: false,
					}
				}
			}
			if(streams && streams.data){
				const {data, videoStatus, muteStatus} = streams
				options.videoStream = data
				if(videoStatus == 0){
					camera = false
					readyStatus = -1
					dispatch('sendMessage', message2)
				}
				if(muteStatus == 0){
					muteFlag = true
					dispatch('sendMessage', message)
				}
			}else{
				let videoStream = null
				let audioStream = null
				try{
					videoStream = await dispatch('getDevicesInfo', {video: true})
				}catch(e){
					camera = false
					dispatch('sendMessage', message2)
					console.log(e)
				}
				try{
					audioStream = await dispatch('getDevicesInfo', {audio: true})
				}catch(e){
					muteFlag = true
					dispatch('sendMessage', message)
					console.log(e)
				}
				if(videoStream && audioStream){
					videoStream.addTrack(audioStream.getAudioTracks()[0])
				}else if(!videoStream && audioStream){
					videoStream = audioStream
					readyStatus = -1
				}
				if(videoStream){
					options.videoStream = videoStream
				}else{
					readyStatus = -1
				}
			}
			let participant = reactive(new (Participant as any)(name, camera, muteFlag, readyStatus))
			participant.mode = mode
			commit('setParticipant', participant)
			await nextTick()
			/**延迟1秒再往下执行，防止video标签获取不到**/
			let video = document.getElementById('video' + name)
			options.localVideo = video
			options.onicecandidate = participant.onIceCandidate.bind(participant)
			commit('setOptions', options)
			participant.rtcPeer = new (kurentoUtils as any).WebRtcPeer.WebRtcPeerSendonly(options, function(error:any) {
				if (error){
					participant.getError()
					// ElMessage.error(String(error))
					return console.log(error)
				}
				participant.rtcPeer.generateOffer(participant.offerToReceiveVideo.bind(participant))
			})
			// commit('setParticipant', participant)
		},
		/**共享屏幕**/
		shareDesk({ commit, dispatch, state }:any){
			const { roomInfo: {  name } } = state
			const participant = state.participants[name]
			navigator.mediaDevices.getDisplayMedia({video: true}).then(async(stream) => {
				dispatch('replaceVideoTrack', stream)
				// await nextTick()
				// dispatch('addAudioTrack', stream)
				stream.getVideoTracks()[0].onended = ()=>{
					dispatch('endDesk')
				}
				commit('setdeskData', {name})
				if(participant.videoStatus == 0){
					participant.setMuted('video')
				}
			}).catch(error => {
				console.log('error', error)
			})
		},
		/**共享屏幕（没有摄像头麦克风推流）**/
		shareDesk2({ commit, dispatch, state }:any){
			let { roomInfo: {  name }, participants, options } = state
			const participant = state.participants[name]
			navigator.mediaDevices.getDisplayMedia({video: true}).then(async(stream) => {
				commit('setdeskData', {name})
				options.videoStream = stream
				participant.rtcPeer = new (kurentoUtils as any).WebRtcPeer.WebRtcPeerSendonly(options, function(error:any) {
					if (error){
						participant.getError()
						// ElMessage.error(String(error))
						return console.log(error)
					}
					participant.rtcPeer.generateOffer(participant.offerToReceiveVideo.bind(participant))
				})
			}).catch(error => {
				console.log('error', error)
			})
		},
		/**结束桌面共享**/
		endDesk({ commit, state, dispatch }:any){
			let { roomInfo: {  name }, participants } = state
			const participant = state.participants[name]
			if(participant.cameraStatus == 1){
				navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
					dispatch('replaceVideoTrack', stream)
					commit('setdeskData', {})
				}).catch(error => {
					console.log('error', error)
				});
			}else{
				let video:any = document.getElementById(`video${name}`)
				let oldStream = video.srcObject
				let tracks = oldStream.getVideoTracks()
				oldStream.getTracks().forEach((track:any) => {
					if(track.kind == 'video') track.stop()
				})
				if(tracks.length) oldStream.removeTrack(oldStream.getVideoTracks()[0])
				participant.setPara({readyStatus: -1, videoStatus: 0})
				commit('setdeskData', {})
				participant.checkeds = false
			}
		},
		/**修改推送的视频流**/
		replaceVideoTrack({ commit, dispatch, state }:any, stream:any){
			const { roomInfo: {  name }, participants } = state
			const {rtcPeer} = participants[name]
			let video:any = document.getElementById(`video${name}`)
			let oldStream = video.srcObject
			let newVideoTrack = stream.getVideoTracks()[0]
			let tracks = oldStream.getVideoTracks()
			oldStream.getTracks().forEach((track:any) => {
				if(track.kind == 'video') track.stop()
			})
			if(tracks.length) oldStream.removeTrack(oldStream.getVideoTracks()[0])
			oldStream.addTrack(newVideoTrack)
			video.srcObject = oldStream
			let sender = rtcPeer.peerConnection.getSenders()[1]
			sender.replaceTrack(newVideoTrack)
		},
		/**添加音频流**/
		addAudioTrack({ commit, dispatch, state }:any, stream:any){
			const { roomInfo: {  name }, participants } = state
			const {rtcPeer} = participants[name]
			let video:any = document.getElementById(`video${name}`)
			let oldStream = video.srcObject
			let newVideoTrack = stream.getAudioTracks()[0]
			oldStream.addTrack(newVideoTrack)
			video.srcObject = oldStream
			let sender = rtcPeer.peerConnection
			sender.addTrack(newVideoTrack, oldStream)
		},
		/**修改推送的音频流**/
		replaceAudioTrack({ commit, dispatch, state }:any, stream:any){
			const { roomInfo: {  name }, participants } = state
			const {rtcPeer} = participants[name]
			let video:any = document.getElementById(`video${name}`)
			let oldStream = video.srcObject
			let newVideoTrack = stream.getAudioTracks()[0]
			oldStream.getTracks().forEach((track:any) => {
				if(track.kind == 'audio') track.stop()
			})
			oldStream.removeTrack(oldStream.getAudioTracks()[0])
			// oldStream.addTrack(newVideoTrack)
			video.srcObject = oldStream
			let sender = rtcPeer.peerConnection.getSenders()[1]
			sender.replaceTrack(newVideoTrack)
			
		},
		async closeWs({ commit, state }:any){
			const { ws } = state
			if(ws && ws.readyState == ws.OPEN){
				ws.close()
			}
			await nextTick()
			commit('setWs', null)
		},
		/**获取分享桌面权限和添加音轨**/
		getStream(){
			return new Promise((resolve, reject) => {
				navigator.mediaDevices.getDisplayMedia({video: true,audio: false}).then((stream) => {
				    navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((audioStream) => {
						stream.addTrack(audioStream.getAudioTracks()[0])
						resolve(stream)
				    }).catch(error => {
						reject(error)
					});
				}).catch(error => {
					reject(error)
				});
			})
		},
		/**websocket发送消息**/
		sendMessage({ commit, dispatch, state }:any, message:any){
			const { ws } = state
			let Msg = JSON.stringify(message)
			if(ws && ws.readyState == ws.OPEN){
				ws.send(Msg)
				const { method, params } = message
				if(method == 'joinRoom') commit('setRoomInfo', params)
			}
		},
		/**获取本地媒体设备**/
		getDevicesInfo({  }:any, constraints:any){
			return new Promise((resolve, reject) => {
				navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
					resolve(stream)
				}).catch(error => {
					reject(error)
				})
			})
		},
		/**停止流**/
		stopStream({  }:any, {stream, type}:any){
			return new Promise((resolve) => {
				stream.getTracks().forEach((track:any) => {
					if(!type || track.kind == type) track.stop()
				})
				if(type == 'audio'){
					stream.removeTrack(stream.getAudioTracks()[0])
				}else if(type == 'video'){
					stream.removeTrack(stream.getVideoTracks()[0])
				}
				resolve(stream)
			})
		},
	}
}