export const useWebSocket = ({ wsUri, onMessage, onClosed, onSuccess }:any) => {
	let ws:any = null;
	interface timeout{
		timeoutObj: any | null,
		serverTimeoutObj: any | null,
	}
	let timeouts: timeout = { timeoutObj: null, serverTimeoutObj: null, }
	console.log(333,wsUri)
	const bindWsEvent = () => {
		//连接成功
		ws.addEventListener('open', ({ type }:any) => {
			console.log('连接成功 ---', type,)
			heartCheck.start()
			if(typeof onSuccess == 'function') onSuccess()
		})
		//连接关闭
		ws.addEventListener('close', ({ type }:any) => {
			console.log('连接关闭 ---', type)
			timeouts.timeoutObj && clearTimeout(timeouts.timeoutObj);
			timeouts.serverTimeoutObj && clearTimeout(timeouts.serverTimeoutObj);
			if(typeof onClosed == 'function') onClosed()
		})
		//连接错误
		ws.addEventListener('error', ({ type }:any) => {
			console.log('连接错误 ---', type)
		})
		//消息接收
		ws.addEventListener('message', (res:any) => {
			heartCheck.start()
			if(typeof onMessage == 'function') onMessage(res)
		})
	}
	
	// 初始化函数
	const init = () => {
		bindWsEvent()
	}
	
	var lockReconnect = false;//避免重复连接
	var tt:any = null;
	const reconnect = () => {
		if (lockReconnect) {
			return;
		};
		lockReconnect = true;
		//没连接上会一直重连，设置延迟避免请求过多
		tt && clearTimeout(tt);
		tt = setTimeout(function() {
			createWebSocket();
			lockReconnect = false;
		}, 4000);
	}
	
	const createWebSocket = () => {
		try {
			ws = new WebSocket(wsUri)
			init()
		} catch (e) {
			console.log('catch', e);
			// reconnect(wsUrl);
			if(typeof onClosed == 'function') onClosed()
		}
	}
	createWebSocket()
	
	//心跳检测
	var heartCheck = {
		timeout: 30000, //每隔三秒发送心跳
		severTimeout: 5000, //服务端超时时间
		start: function() {
			var _this = this;
			timeouts.timeoutObj && clearTimeout(timeouts.timeoutObj);
			timeouts.serverTimeoutObj && clearTimeout(timeouts.serverTimeoutObj);
			timeouts.timeoutObj = setTimeout(function() {
				//这里发送一个心跳，后端收到后，返回一个心跳消息，
				//onmessage拿到返回的心跳就说明连接正常
				try {
					ws.send(JSON.stringify({// 心跳包
						method: 'heartbeat',
					}))
				}catch (e) {
					console.log('catch1', e);
					// reconnect(wsUrl);
				}
				//计算答复的超时时间
				timeouts.serverTimeoutObj = setTimeout(function() {
					console.log(12333,'timeout to close')
					ws.close();
				}, _this.severTimeout);
			}, _this.timeout)
		}
	}
	
	return ws
}
